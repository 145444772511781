import { default as _91id_93YZKDOrfUnaMeta } from "/usr/src/nuxt-app/pages/article/[id].vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
export default [
  {
    name: "article-id",
    path: "/article/:id()",
    component: () => import("/usr/src/nuxt-app/pages/article/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  }
]